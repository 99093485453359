.loader {
  padding-top: 15px;
  // padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  font-family: "Spectral SemiBold", sans-serif;
  font-weight: 300;
  background: #222 -webkit-radial-gradient(50% 0, ellipse farthest-corner, #444
        0%, #222 100%);
  &__title {
    // @extend .header__content__title;
    margin-top: 4px;
    width: 100px;
    color: white;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    font-family: "montserrat", sans-serif;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
  }
  &__element {
    width: 8px;
    height: 8px;
    margin: 40px auto;
    opacity: 0.5;
    border-radius: 50%;
    box-shadow: 0 -14px 0 rgba(255, 255, 255, 0.8),
      10px -10px 0 rgba(255, 255, 255, 0.7),
      14px 0px 1px rgba(255, 255, 255, 0.6),
      10px 10px 1px rgba(255, 255, 255, 0.5),
      0px 14px 2px rgba(255, 255, 255, 0.4),
      -10px 10px 2px rgba(255, 255, 255, 0.3),
      -14px 0px 3px rgba(255, 255, 255, 0.2),
      -10px -10px 3px rgba(255, 255, 255, 0.1);
    transform-origin: 50%;
    animation: load 0.5s steps(8, end) infinite;
  }
}

@keyframes load {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
