button,
.button {
  display: inline-block;
  border: none;
  &__about {
    background-color: $font-color3;
    border: 2px solid $font-color3;
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 20px 30px;
    border-radius: 28px;
    width: fit-content;
    transition: background-color 0.3s;
    &:hover,
    &:focus {
      background-color: darken($font-color3, 10%);
      border-color: darken($font-color3, 10%);
      cursor: pointer;
    }
    &:hover:active {
      background-color: white;
      color: $font-color3;
    }
    &:active {
      background-color: darken($font-color3, 15%);
    }
  }
  &__show-more {
    position: absolute;
    bottom: 20px;
    height: 30px;
    padding: 0 10px;
    background-color: $background-color3;
    border: 1px solid $background-color3;
    border-radius: 5px;
    color: white;
    transition: all 0.4s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: lighten($background-color3, 20%);
      border: 1px solid lighten($background-color3, 20%);
      color: black;
      &:active {
        background: white;
      }
    }
  }
  &__skill {
    height: 30px;
    border-radius: 5px;
    padding: 0 10px;
    background-color: $font-color3;
    color: white;
  }
  &__scroll {
    border: none;
    border-radius: 5px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    width: 50px;
    height: 60px;
    background: url(../../img/to-the-moon2.webp);
    opacity: 0.4;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:hover::after {
      opacity: 1;
      z-index: +1;
    }
    &::after {
      content: "";
      background: url(../../img/to-the-moon4.webp);
      background-repeat: no-repeat;
      background-size: cover;
      width: inherit;
      height: inherit;
      position: fixed;
      bottom: 20px;
      right: 19.5px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  &__github,
  &__live-demo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 3rem;
    border: 1px solid transparent;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      font-size: 3.1rem;
    }
    &hover:active {
      border: 1px solid black;
    }
  }
}
