.navbar {
  z-index: 999;
  // background-color: antiquewhite;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  // background-color: salmon;

  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.4s;
}
