/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 25px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $logo-color;
  transition: all 0.2s ease-in-out;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: darken($logo-color, 10%);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 50px;
  width: 50px;
  &:hover .bm-cross {
    background-color: $background-color2;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background: $background-color3;
  transition: all 0.3s ease-in-out;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #333;
  // background: #222 -webkit-radial-gradient(10% 0%, ellipse farthest-corner, #444
  //       50%, #222 100%);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  position: relative;
  color: #b8b7ad;
  padding: 0.8em;
  //styling
  .menu {
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    color: red;
    width: 100%;
    a {
      display: block;
      width: 100%;
      &:hover li {
        color: $background-color3;
        border-bottom: 1px solid $background-color3;
      }
    }
    li {
      display: block;
      border-bottom: 1px solid white;
      padding-bottom: 10px;
      transition: all 0.3s ease-in-out;
    }
    &__item[title]:hover:after {
      bottom: 0; /* Adjust the distance from the bottom */
      left: 50%; /* Center the tooltip horizontally */
      transform: translateX(-35%); /**/
      padding-bottom: 0;
    }
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.9);
  width: 100vw;
}
.menu-item {
  color: pink;
}

@media (min-width: 800px) {
  .bm-burger-button {
    display: none;
  }
}

@media (max-width: 375px) {
  .bm-burger-button {
    right: 15px;
  }
}
