.footer {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 40px 0;
  background-color: white;
  span {
    font-family: "montserrat" sans-serif;
    font-weight: 700;
    color: $logo-color;
  }
}
