.flipcard {
  position: absolute;
  width: 100%;
  height: 100%;
  &__container {
    position: relative;
    width: 100%;
    min-width: 310px;
    height: 100%;
    border: 5px solid white;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    &:hover {
      cursor: pointer;
    }
  }
  //front of the card
  &__front-cover {
    width: 100%;
    height: auto;
    object-fit: cover;
    border: 5px solid $background-color2;
  }
  &__logo {
    position: absolute;
    left: 0;
    bottom: 10px;
    transform: translateX(50%);
    height: 50px;
    width: 50%;
    padding: 10px;
    object-fit: contain;
    background-color: white;
  }
  //back of the card
  &__back {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 5px solid $background-color2;
  }
  &__back-cover {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    opacity: 20%;
  }
  &__back-content {
    padding: 10px 20px 0;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 5px;
  }
  .logo-oc {
    margin-left: 20px;
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  p {
    margin-top: 5px;
    text-transform: uppercase;
  }
  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.2rem;
    text-align: center;
  }
  &__competence {
    margin-bottom: 10px;
  }
  &__skill {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    margin-right: 20px;
  }
  &__back-links-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    width: 100%;
    margin-bottom: 5px;
  }
}
@media (max-width: 450px) {
  .flipcard {
    max-height: 100vw;
    &__container {
      max-height: 100vw;
    }
    &__back-content {
      padding: 10px 10px 0;
    }
    &__skill {
      margin-right: 20px;
      margin-top: 5px;
    }
  }
}
