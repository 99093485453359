.menu {
  margin-right: 100px;
  gap: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  li {
    color: white;
    text-transform: capitalize;
    // color: $font-color1;
    font-weight: 700;
    font-size: 15px;
    line-height: 40px;
    height: 40px;
    letter-spacing: 0.3px;
  }
  li:hover {
    cursor: pointer;
  }
  .inactive {
    position: relative;
    display: inline-block;
  }
  .inactive:hover {
    cursor: pointer;
  }
}

.menu__item[title]:hover:after {
  content: attr(content);
  display: inline-block;
  position: absolute;
  background-color: #333; /* Set the background color */
  color: #fff; /* Set the text color */
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
  bottom: -50px; /* Adjust the distance from the bottom */
  left: 50%; /* Center the tooltip horizontally */
  transform: translateX(-50%); /* Center the tooltip horizontally */
}

@media (max-width: 799px) {
  .menu {
    display: none;
  }
}
