/* Line */

.lines {
  position: absolute;
  top: 80px;
  height: 100%;
  left: 0;
  right: 0;

  margin: auto;
  width: 84vw;
  z-index: -1;
}

.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #f2f2f2;
  overflow: hidden;
}

.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 5vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    color-stop(75%, #000000),
    to(#000000)
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    #000000 75%,
    #000000 100%
  );
  -webkit-animation: run 15s 0s infinite;
  animation: run 15s 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.49, 0.01, 0.43, 0.97);
  animation-timing-function: cubic-bezier(0.49, 0.01, 0.43, 0.97);
}

.lines .line:nth-child(1) {
  margin-left: -50%;
}

.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.lines .line:nth-child(2) {
  margin-left: -25%;
}

.lines .line:nth-child(2)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.lines .line:nth-child(3) {
  margin-left: 25%;
}

.lines .line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lines .line:nth-child(4) {
  margin-left: 50%;
}
.lines .line:nth-child(4)::after {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

@-webkit-keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lines {
    width: 90vw;
  }
  .lines .line:nth-child(2) {
    display: none;
  }
  .lines .line:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .lines .line:nth-child(2) {
    display: none;
  }
  .lines .line:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 479px) {
  .lines {
    width: 92vw;
  }
  .lines .line:nth-child(2) {
    display: none;
  }
  .lines .line:nth-child(3) {
    display: none;
  }
}
@media screen and (max-width: 320px) and (min-width: 0px) {
  .lines {
    width: 92vw;
  }
  .lines .line:nth-child(2) {
    display: none;
  }
  .lines .line:nth-child(3) {
    display: none;
  }
}
