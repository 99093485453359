// Global styles
* {
  box-sizing: border-box;
}
#root {
  font-family: "Josefin Sans", sans-serif;
  font-size: 15px;
}
a {
  text-decoration: none;
}
.ancres {
  display: block;

  height: 80px; /*same height as header*/

  margin-top: -80px; /*same height as header*/
  visibility: hidden;
}

%section-title {
  h2 {
    font-family: "montserrat", sans-serif;
    font-weight: 900;
    font-size: 45px;
    text-transform: uppercase;
    line-height: 1.3;
    color: $title-color1;
  }
}
