.projects {
  @extend %section-title;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  &__title {
    margin-bottom: 70px;
  }
  &__cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    flex-wrap: wrap;
    padding: 70px 4vw;
    background-color: $background-color2;
  }
}

@media screen and (max-width: 1440px) {
  .projects {
    &__cards-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media screen and (max-width: 1100px) {
  .projects {
    &__cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media screen and (max-width: 768px) {
  .projects {
    &__cards-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
@media screen and (max-width: 375px) {
  .projects {
    &__cards-container {
      padding: 70px 1vw;
    }
  }
}
