.services {
  @extend %section-title;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding-top: 70px;
  .services__content {
    padding-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100vw;
  }
  .service__item {
    width: 30vw;
    background-color: white;
    padding: 5px;
    border: 5px solid $background-color2;
    &__container {
      background-color: $background-color2;
      padding: 30px 20px;
      height: 100%;
    }
    &__icon {
      font-size: 2rem;
      padding: 5px;
      margin-bottom: 20px;
      width: 73px;
      height: 73px;
      border: 5px solid $background-color1;
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      margin-bottom: 10px;
      font-weight: 700;
    }
    &__description {
      line-height: 24px;
    }
  }
}
@media (max-width: 1024px) {
  .services {
    .service__item {
      width: 45vw;
    }
  }
}
@media (max-width: 768px) {
  .services {
    .service__item {
      width: 90vw;
    }
  }
}
