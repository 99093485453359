#logo,
.logo {
  width: 200px;
  display: flex;
  height: 80px;
  color: white;
  width: 500px;
  transition: color 0.5s;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
  }
  &__symbol {
    width: 50px;
    // height: 100%;
    margin: 0 20px;
    align-self: center;
    display: inline-block;
    font-family: "Nanum Myeongjo", serif;
    font-size: 2.8rem;
    font-weight: 800;
    stroke: $logo-color;
    fill: $logo-color;

    // color: white;
  }
  &__text-container {
    // height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-bottom: 10px;
  }
  &__title {
    width: 50px;
    // display: flex;
    height: 20px;
    font-family: "montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.05rem;
    text-align: center;
    // padding-top: 5px;
    line-height: 20px;
    // height: 20px;
    // justify-self: center;
  }
  &__slogan {
    display: flex;
    flex-direction: column;
    // align-self: flex-end;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    // height: 20px;
    // line-height: 10px;
    // height: 100%;
  }
}
