$font-color1: #2d2d2d;
$title-color1: #333;
$font-color2: #fff;
$font-color3: #ea1538;
$background-color1: #fff;
$background-color2: #f7f7f7;
$background-color3: #a5a5a5;
$logo-color: #00b2b9;
$error-color: #ff4d15;
$success-color: #03b103;
