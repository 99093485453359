.about {
  @extend %section-title;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  &__container {
    width: 100%;
    padding-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    h3 {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
    a {
      display: inline-block;
      width: fit-content;
    }
  }
  &__picture {
    width: 40vw;
    width: 450px;
    height: 500px;
    background: url(../../img/portrait.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    width: 40vw;
    .color-text {
      color: $font-color3;
    }
    .bold-text {
      margin-right: 5px;
      font-weight: 700;
    }
    button {
      margin-top: 25px;
    }
  }
}

@media (max-width: 1024px) {
  .about {
    &__picture {
      width: 370px;
      height: 480px;
    }
    &__content {
      width: 50vw;
    }
  }
}
@media (max-width: 768px) {
  .about {
    &__container {
      flex-direction: column;
      align-items: center;
    }
    &__picture {
      width: 80vw;

      height: 500px;
    }
    &__content {
      margin-top: 35px;
      width: 80vw;
    }
  }
}
